import { IDateRange } from "@/models/common-models"
import { dossierPatientModel } from "@/models/dossier-response-model"

export const prescriptionsPageNavitems = [
  {
    icon: 'mdi-clipboard-text-search-outline',
    title: 'Recherche',
    to: '/prescriptions'
  },
  {
    icon: 'mdi-restart',
    title: 'Renouvellements',
    to: '/prescriptions/renewals'
  }
]

export const prescriptionsPageNoRenewalsNavitems = [
  {
    icon: 'mdi-clipboard-text-search-outline',
    title: 'Recherche',
    to: '/prescriptions'
  }
]

export const isRenewedOptionsTous = 0

export const isRenewedOptions = [
  { text: 'Tous', value: isRenewedOptionsTous },
  { text: 'Oui', value: 1 },
  { text: 'En attente', value: 2 },
  { text: 'Non', value: 3 }
]

export const isRenewedFlagPending = 2

export const isRenewedFlag = [
  { text: 'Oui', value: 1 },
  { text: 'En attente', value: isRenewedFlagPending },
  { text: 'Non', value: 3 }
]

export interface PrescriptionSearchModel {
  patient?: dossierPatientModel;
  prestationType?: number;
  expiryDate?: IDateRange;
  prescriptionStatus?: string;
  prescriptionType?: string;
  site?: string;
  district?: string[];
  medecinId?: string;
  renewed: number;
}

export interface PrescriptionSearchRequest {
  patientId?: string;
  prestationType?: number;
  expiryDate?: IDateRange;
  prescriptionStatus?: string;
  prescriptionType?: string;
  site?: string;
  district?: string[];
  medecinId?: string;
  renewed: number;
}

export interface RenewablePrescriptionSearchRequest {
  prestationType: number;
  patientId?: string;
}

import { PagedCollection } from '@/components/shared/Helpers/common-models'
import Commons from '@/components/shared/Helpers/commons'
import http from '@/http-client'
import { PendingPrestationResponseModel, PrescriptionWithPrestationsSearchResponseModel, PrescriptionWithPrestationsToRenewResponseModel, PrestationModel, PrestationsToRenewResponseModel } from '@/models/prescriptions-model'
import { PrescriptionSearchRequest, RenewablePrescriptionSearchRequest } from '@/views/Prescriptions/Constants'
import { DataOptions } from 'vuetify'

export class PrescriptionService {
  private static instance: PrescriptionService

  public static getInstance (): PrescriptionService {
    if (!PrescriptionService.instance) {
      PrescriptionService.instance = new PrescriptionService()
    }
    return PrescriptionService.instance
  }

  public async getPrescriptionsToRenew (searchModel: RenewablePrescriptionSearchRequest) {
    const response = await http.post('/prescription/renew', searchModel)
    return (response.data) as PrescriptionWithPrestationsToRenewResponseModel<PrestationsToRenewResponseModel>[]
  }

  public async getPrescriptionsWithPendingPrestations (searchModel: RenewablePrescriptionSearchRequest) {
    const response = await http.post('/prescription/pending', searchModel)
    return (response.data) as PrescriptionWithPrestationsToRenewResponseModel<PendingPrestationResponseModel>[]
  }

  public async renewPrestations (ids: string[]) {
    return await http.put('/prescription/renew', ids)
  }

  public async searchPrescriptions (searchModel: PrescriptionSearchRequest, options: DataOptions) {
    const queryString: string[] = []
    Commons.PushPagingOptionsToQuerystring(queryString, options)
    if (options.sortBy.length === options.sortDesc.length) {
      options.sortBy.forEach((e, i) => {
        if (e === 'patientName') {
          const p1 = 'PatientNom'
          const p2 = 'PatientPrenom'
          let s1 = `orderBy=${p1}`
          let s2 = `orderBy=${p2}`
          if (options?.sortDesc[i] === true) {
            s1 += ' desc'
            s2 += ' desc'
          }
          queryString.push(s1)
          queryString.push(s2)
        } else if (e === 'prescriptionDoctorName') {
          const p1 = 'DoctorNom'
          const p2 = 'DoctorPrenom'
          let s1 = `orderBy=${p1}`
          let s2 = `orderBy=${p2}`
          if (options?.sortDesc[i] === true) {
            s1 += ' desc'
            s2 += ' desc'
          }
          queryString.push(s1)
          queryString.push(s2)
        } else {
          let s = `orderBy=${e}`
          if (options?.sortDesc[i] === true) {
            s += ' desc'
          }
          queryString.push(s)
        }
      })
    }
    const qs = `?${queryString.join('&')}`
    const response = await http.post(`/prescription/search${qs}`, searchModel)
    return (response.data) as PagedCollection<PrescriptionWithPrestationsSearchResponseModel>
  }

  public async updatePrescriptionStatus (prescription: PrescriptionWithPrestationsToRenewResponseModel<PrestationsToRenewResponseModel>) {
    prescription.prestations = []
    return await http.put(`/prescription/updatestatus/${prescription.id}`, prescription)
  }

  public async updatePrestationRenewedState (prestation: PrestationsToRenewResponseModel) {
    return await http.put(`/prescription/updaterenewed/${prestation.id}`, prestation)
  }
}

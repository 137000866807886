import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { prescriptionsPageNavitems, prescriptionsPageNoRenewalsNavitems, RenewablePrescriptionSearchRequest } from '../Constants'
import Alert from '@/components/shared/Alert.vue'
import { getPrescriptionStatusText, getPrestationCategoryName, renewablePrestationCategories, renewablePrestationCategoriesAll } from '@/views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import { PrescriptionService } from '@/services/prescription-service'
import { IPrestationRenewable, PendingPrestationResponseModel, PrescriptionWithPrestationsToRenewResponseModel, PrestationsToRenewResponseModel } from '@/models/prescriptions-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import PrestationPrescriptionTypeId from '@/components/shared/TableCellRenderer/PrestationPrescriptionTypeId.vue'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { EvaluationReportModel } from '@/models/consultation-model'
import EditPrestationDialog from '@/components/shared/Dialogs/EditPrestationDialog/EditPrestationDialog.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithAll, accessDeniedError } from '@/shared/constants/Constants'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    EditPrestationDialog,
    PrestationPrescriptionTypeId
  }
})
export default class Renewals extends Vue {
  private patientsService = PatientService.getInstance()
  private prescriptionService = PrescriptionService.getInstance()
  private patientPrescriptionService = PatientPrescriptionService.getInstance()
  private layoutService = LayoutService.getInstance()
  public prescriptionHeaders: any[] = []
  public prestationHeaders: any[] = []
  public pendingPrestationHeaders: any[] = []
  public dossiers: dossierPatientModel[] = []
  public items: PrescriptionWithPrestationsToRenewResponseModel<PrestationsToRenewResponseModel>[] = []
  public isLoading = true
  public selected: PrestationsToRenewResponseModel[] = []

  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public globalCheckboxValue = false
  public globalCheckboxIndeterminate = false
  public generating = false
  public renewablePrestationCategories = renewablePrestationCategories
  public searchModel: RenewablePrescriptionSearchRequest = { prestationType: renewablePrestationCategoriesAll }
  public pendingSearchModel: RenewablePrescriptionSearchRequest = { prestationType: renewablePrestationCategoriesAll }
  public pendingItems: PrescriptionWithPrestationsToRenewResponseModel<PendingPrestationResponseModel>[] = []
  public isLoadingItems = false
  public isLoadingPendingItems = false

  public tab: any = null
  public tabs = ['Prestations à renouveler', 'Prestations en attente']

  public showPrestationDialog = false
  public selectedEditPrestation: IPrestationRenewable | any = {}
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public dbDossiers: dossierPatientModel[] = []
  public searchPatient = ''
  public selectedPatient?: dossierPatientModel
  public readyPatients = true
  public hasAccess = false

  public async mounted () {
    this.hasAccess = ModuleAuthorisationManager.HasAccess('prescription.createPrescriptionRenewals')
    if (this.hasAccess) {
      this.layoutService.updateDrawerList(prescriptionsPageNavitems)
    } else {
      this.updateAlertMessage(accessDeniedError)
      this.layoutService.updateDrawerList(prescriptionsPageNoRenewalsNavitems)
    }
    this.isLoading = false

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.prescriptionHeaders = [
      {
        text: '',
        value: 'checkbox',
        sortable: false
      },
      {
        text: 'Patient',
        value: 'patientName'
      },
      {
        text: 'Date',
        value: 'dateStart',
        formatter: Commons.TransformDateFormat
      },
      {
        text: 'Statut',
        value: 'statusName'
      },
      {
        text: 'Médecin',
        value: 'prescriptionDoctorName'
      },
      {
        text: 'District',
        value: 'district'
      }
    ]
    this.prestationHeaders = [
      {
        text: 'Catégorie',
        value: 'categeoryTypeId',
        formatter: getPrestationCategoryName,
        width: '30%'
      },
      {
        text: 'Début',
        value: 'dateStart',
        formatter: Commons.TransformDateFormat,
        width: '30%'
      },
      {
        text: 'Échéance',
        value: 'dateEnd',
        formatter: Commons.TransformDateFormat,
        width: '30%'
      },
      {
        text: 'Type',
        value: 'prescriptionTypeId',
        width: '10%'
      }
    ]
    this.pendingPrestationHeaders = [...this.prestationHeaders]
    this.pendingPrestationHeaders.push({
      text: 'Envoyé',
      value: 'sent',
      sortable: true,
      align: 'right'
    })
    this.pendingPrestationHeaders.push({
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right'
    })

    let hasReplayed = false
    const replayLastSearch = sessionStorage.getItem('replayLastRenewalsSearch')
    const replayLastPendingSearch = sessionStorage.getItem('replayLastPendingSearch')

    if (replayLastSearch) {
      sessionStorage.removeItem('replayLastRenewalsSearch')
      const lastSearch = sessionStorage.getItem('lastRenewalsSearch')
      if (lastSearch) {
        this.searchModel = JSON.parse(lastSearch)
        this.refreshPrescriptions()
        hasReplayed = true
      }
    }

    if (replayLastPendingSearch) {
      sessionStorage.removeItem('replayLastPendingSearch')
      const lastSearch = sessionStorage.getItem('lastPendingSearch')
      if (lastSearch) {
        this.pendingSearchModel = JSON.parse(lastSearch)
        this.refreshPrescriptions()
        hasReplayed = true
      }
      this.tab = 1
    }

    if (!hasReplayed) {
      await this.refreshPendingPrescriptions()
    }
  }

  private updateAlertSuccessMessage () {
    this.alertType = 'success'
    this.alertMessages = ["Le renouvellement des prestations s'est déroulé avec succès"]
    this.showAlert = true
  }

  private updateAlertMessage (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  public async refreshPrescriptions () {
    this.isLoadingItems = true
    sessionStorage.setItem('lastRenewalsSearch', JSON.stringify(this.searchModel))
    await this.prescriptionService.getPrescriptionsToRenew(this.searchModel).then((p: PrescriptionWithPrestationsToRenewResponseModel<PrestationsToRenewResponseModel>[]) => {
      this.items = p
      Commons.setRowClasses(this.items)
      this.items.forEach((i) => {
        i.statusName = this.getStatusText(i.statusId!)!
      })
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.isLoadingItems = false
    })
    await this.refreshPendingPrescriptions()
  }

  public async refreshPendingPrescriptions () {
    this.isLoadingPendingItems = true
    sessionStorage.setItem('lastPendingSearch', JSON.stringify(this.pendingSearchModel))
    await this.prescriptionService.getPrescriptionsWithPendingPrestations(this.pendingSearchModel).then((p: PrescriptionWithPrestationsToRenewResponseModel<PendingPrestationResponseModel>[]) => {
      this.pendingItems = p
      Commons.setRowClasses(this.pendingItems)
      this.pendingItems.forEach((i) => {
        i.statusName = this.getStatusText(i.statusId!)!
      })
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.isLoadingPendingItems = false
    })
  }

  @Watch('selected') onSelectedChanged () {
    if (this.selected.length > 0) {
      if (this.selected.length >= this.allPrestationsCount) {
        this.globalCheckboxValue = true
        this.globalCheckboxIndeterminate = false
      } else {
        this.globalCheckboxValue = false
        this.globalCheckboxIndeterminate = true
      }
    } else {
      this.globalCheckboxValue = this.globalCheckboxIndeterminate = false
    }
  }

  public get allPrestationsCount () {
    let count = 0
    this.items.forEach(prescription => {
      if (prescription.prestations) {
        count += prescription.prestations.length
      }
    })
    return count
  }

  public selectAll () {
    const countBefore = this.selected.length
    this.selected = []
    if (countBefore < this.allPrestationsCount) {
      this.items.forEach(prescription => {
        if (prescription.prestations) {
          prescription.prestations.forEach(prestation => {
            this.selected.push(prestation)
          })
        }
      })
    }
  }

  public generateRenewals () {
    const ids = this.selected.map(s => s.id ?? '')
    this.generating = true
    this.prescriptionService.renewPrestations(ids).then(async () => {
      await this.refreshPrescriptions()
      this.selected = []
      this.updateAlertSuccessMessage()
      this.tab = 1
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.generating = false
    })
  }

  public getStatusText (id: number) {
    return getPrescriptionStatusText(id)
  }

  public getPatientName (guid: string) {
    return this.dossiers.find(d => d.guid.toLowerCase() === guid.toLowerCase())?.fullName
  }

  public getDistrict (guid: string) {
    return this.dossiers.find(d => d.guid.toLowerCase() === guid.toLowerCase())?.district
  }

  public resetSearch () {
    this.searchModel = {
      prestationType: renewablePrestationCategoriesAll
    }
  }

  public resetPendingSearch () {
    this.pendingSearchModel = {
      prestationType: renewablePrestationCategoriesAll
    }
    this.selectedPatient = undefined
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  public generateMaterielPDFReport (prestationItem: PendingPrestationResponseModel) {
    this.patientPrescriptionService.updatePrestationSelected(prestationItem)
    this.$router.push({ name: 'MaterielReport', params: { id: prestationItem.id! } })
  }

  public generatePrestationRequisesPDFReport (prestationItem: PendingPrestationResponseModel) {
    const reportModel: EvaluationReportModel = {
      evaluations: prestationItem.evaluationInitialItems,
      consultationType: prestationItem.consultationType,
      infirmiereId: prestationItem.createdByUserId,
      appointmentId: prestationItem.appointmentId,
      prescriptionTypeId: 2,
      dossierId: prestationItem.dossierId!,
      prescriptionId: prestationItem.prescriptionId,
      prescriptionDoctor: prestationItem.prescriptionDoctor
    }
    this.patientsService.updateEvaluationReportModelSelected(reportModel)
    this.$router.push({ name: 'EvaluationForm' })
  }

  public editPrestation (item: IPrestationRenewable) {
    this.selectedEditPrestation = item
    this.showPrestationDialog = true
  }

  public closePrestationDialog () {
    this.selectedEditPrestation = {}
    this.refreshPrescriptions()
    this.showPrestationDialog = false
  }

  public prestationChanged (e) {
    if (e === undefined || e === null) {
      this.$nextTick(() => {
        this.resetSearch()
      })
    }
  }

  public pendingPrestationChanged (e) {
    if (e === undefined || e === null) {
      this.$nextTick(() => {
        this.resetPendingSearch()
      })
    }
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbDossiers = []
    }
  }

  public get patients () {
    if (this.selectedPatient?.fullName) {
      return [...this.dbDossiers, this.selectedPatient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    }
    return this.dbDossiers
  }

  public patientChanged (e) {
    this.selectedPatient = this.dbDossiers.find(g => g.guid.toLowerCase() === e?.guid?.toLowerCase())
    this.pendingSearchModel.patientId = this.selectedPatient?.guid
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getActivePatients(this.searchPatient).then((ds: any) => {
      this.dbDossiers = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }
}

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { IPrestationRenewable } from '@/models/prescriptions-model'
import { PrescriptionService } from '@/services/prescription-service'
import { isRenewedFlag } from '@/views/Prescriptions/Constants'

@Component({
  components: {
    Alert
  }
})
export default class EditPrestationDialog extends Vue {
  private prescriptionService = PrescriptionService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public item!: IPrestationRenewable

  public isRenewedFlag = isRenewedFlag

  public isSaving = false
  private errorMessages: string[] = []

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()
    }
  }

  public mounted () {
    this.resetDialog()
  }

  private resetDialog () {
    this.errorMessages = []
  }

  get show () {
    return this.visible
  }

  public async updatePrestationRenewedState () {
    this.isSaving = true
    const res = await this.prescriptionService.updatePrestationRenewedState(this.item)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isSaving = false
      })
    if (res) {
      this.close()
    }
  }

  public close () {
    this.$emit('close')
  }

  public hideAlert () {
    this.errorMessages = []
  }
}

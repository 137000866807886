















import { PrestationWithPrescriptionType } from '@/models/prescriptions-model'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class PrestationPrescriptionTypeId extends Vue {
  @Prop()
  public item!: PrestationWithPrescriptionType
}
